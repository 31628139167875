<template>
  <div id="chemical">
    <el-dialog
      :title="chemicalFormTitle"
      width="680px"
      :visible.sync="chemicalDialogVisible"
      :close-on-click-modal="false"
      @close="chemicalDialogClose"
    >
      <el-form
        ref="chemicalFormRef"
        :model="chemicalForm"
        :rules="chemicalFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="品名" prop="productName">
              <el-input v-model="chemicalForm.productName" placeholder="请输入品名" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="chemicalForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发放日期" prop="provideDate">
              <el-date-picker v-model="chemicalForm.provideDate" placeholder="请选择发放日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产厂家" prop="manufacturer">
              <el-input v-model="chemicalForm.manufacturer" placeholder="请输入生产厂家" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号(批号)" prop="batchNum">
              <el-input v-model="chemicalForm.batchNum" placeholder="请输入编号(批号)" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="进入量" prop="entryQuantity">
              <el-input v-model="chemicalForm.entryQuantity" placeholder="请输入进入量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发出量" prop="issueQuantity">
              <el-input v-model="chemicalForm.issueQuantity" placeholder="请输入发出量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结存量" prop="balanceQuantity">
              <el-input v-model="chemicalForm.balanceQuantity" placeholder="请输入结存量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用途" prop="purpose">
              <el-input v-model="chemicalForm.purpose" placeholder="请输入用途" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用部门" prop="collectionDept">
              <el-input v-model="chemicalForm.collectionDept" placeholder="请输入领用部门" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用人员" prop="collector">
              <el-input v-model="chemicalForm.collector" placeholder="请输入领用人员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理人员1" prop="manager1">
              <el-input v-model="chemicalForm.manager1" placeholder="请输入管理人员1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理人员2" prop="manager2">
              <el-input v-model="chemicalForm.manager2" placeholder="请输入管理人员2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="chemicalForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="chemicalDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="chemicalFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="品名">
        <el-input v-model="searchForm.productName" placeholder="请输入品名" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="chemicalPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="品名" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column label="发放日期">
        <template slot-scope="scope">
          <span v-if="scope.row.provideDate">{{ scope.row.provideDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="manufacturer" label="生产厂家" />
      <el-table-column prop="batchNum" label="编号(批号)" />
      <el-table-column prop="entryQuantity" label="进入量" />
      <el-table-column prop="issueQuantity" label="发出量" />
      <el-table-column prop="balanceQuantity" label="结存量" />
      <el-table-column prop="purpose" label="用途" />
      <el-table-column prop="collectionDept" label="领用部门" />
      <el-table-column prop="collector" label="领用人员" />
      <el-table-column prop="manager1" label="管理人员1" />
      <el-table-column prop="manager2" label="管理人员2" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="chemicalPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addChemical, deleteChemical, updateChemical, selectChemicalInfo, selectChemicalList } from '@/api/storage/chemical'

export default {
  data () {
    return {
      chemicalDialogVisible: false,
      chemicalFormTitle: '',
      chemicalForm: {
        id: '',
        productName: '',
        spec: '',
        provideDate: '',
        manufacturer: '',
        batchNum: '',
        entryQuantity: '',
        issueQuantity: '',
        balanceQuantity: '',
        purpose: '',
        collectionDept: '',
        collector: '',
        manager1: '',
        manager2: '',
        remarks: ''
      },
      chemicalFormRules: {
        productName: [{ required: true, message: '品名不能为空', trigger: ['blur', 'change']}]
      },
      chemicalPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectChemicalList(this.searchForm).then(res => {
      this.chemicalPage = res
    })
  },
  methods: {
    chemicalDialogClose () {
      this.$refs.chemicalFormRef.resetFields()
    },
    chemicalFormSubmit () {
      if (this.chemicalFormTitle === '危险化学品领发详情') {
        this.chemicalDialogVisible = false
        return
      }
      this.$refs.chemicalFormRef.validate(async valid => {
        if (valid) {
          if (this.chemicalFormTitle === '新增危险化学品领发') {
            await addChemical(this.chemicalForm)
          } else if (this.chemicalFormTitle === '修改危险化学品领发') {
            await updateChemical(this.chemicalForm)
          }
          this.chemicalPage = await selectChemicalList(this.searchForm)
          this.chemicalDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.chemicalFormTitle = '新增危险化学品领发'
      this.chemicalDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteChemical(row.id)
        if (this.chemicalPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.chemicalPage = await selectChemicalList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.chemicalFormTitle = '修改危险化学品领发'
      this.chemicalDialogVisible = true
      this.selectChemicalInfoById(row.id)
    },
    handleInfo (index, row) {
      this.chemicalFormTitle = '危险化学品领发详情'
      this.chemicalDialogVisible = true
      this.selectChemicalInfoById(row.id)
    },
    selectChemicalInfoById (id) {
      selectChemicalInfo(id).then(res => {
        this.chemicalForm.id = res.id
        this.chemicalForm.productName = res.productName
        this.chemicalForm.spec = res.spec
        this.chemicalForm.provideDate = res.provideDate
        this.chemicalForm.manufacturer = res.manufacturer
        this.chemicalForm.batchNum = res.batchNum
        this.chemicalForm.entryQuantity = res.entryQuantity
        this.chemicalForm.issueQuantity = res.issueQuantity
        this.chemicalForm.balanceQuantity = res.balanceQuantity
        this.chemicalForm.purpose = res.purpose
        this.chemicalForm.collectionDept = res.collectionDept
        this.chemicalForm.collector = res.collector
        this.chemicalForm.manager1 = res.manager1
        this.chemicalForm.manager2 = res.manager2
        this.chemicalForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectChemicalList(this.searchForm).then(res => {
        this.chemicalPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectChemicalList(this.searchForm).then(res => {
        this.chemicalPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectChemicalList(this.searchForm).then(res => {
        this.chemicalPage = res
      })
    }
  }
}
</script>

<style>
</style>
